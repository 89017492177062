<template>
  <div class="">
    <!-- <v-btn
      v-if="$admin.can('batteries.create')"
      color="primary"
      @click="openModal"
    >
      {{ $t("add_new") }}
    </v-btn> -->
    <v-dialog
      v-model="isVisibleDialog"
      persistent
      max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="$admin.can('batteries.create')"
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
          @click="openModal"
        >
          {{ $t("add_new") }}
        </v-btn>
      </template>

      <v-form
        ref="form"
        lazy-validation
      >
        <v-card>
          <v-card-title>
            <span class="text-h3">{{ $t("add_new") }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  v-if="authUser.isAdmin"
                  cols="12"
                  sm="6"
                >
                  <v-select
                    v-model="battery.product"
                    dense
                    :items="['oem', 'fleet']"
                    :label="$t('select_product_*')"
                    :rules="[rules.required]"
                    @change="
                      () => {
                        delete battery.tenant_id;
                        // delete battery.user_type;
                      }
                    "
                  />
                </v-col>
                <v-col
                  v-if="authUser.isAdmin"
                  cols="12"
                  sm="6"
                >
                  <v-select
                    v-model="battery.tenant_id"
                    dense
                    :loading="tenantLoading"
                    :items="tenantsByProduct"
                    item-text="name"
                    item-value="id"
                    :label="$t('select_tenant_*')"
                    :rules="[rules.required]"
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-select
                    v-model="battery.battery_make_id"
                    dense
                    :items="batteryMakesList"
                    :loading="isLoadingBatteryMakesList"
                    item-value="id"
                    item-text="name"
                    :label="$t('battery_make_*')"
                    :rules="[rules.required]"
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-select
                    v-model="battery.battery_model_id"
                    dense
                    :items="batteryModelsList"
                    :loading="isLoadingBatteryModels"
                    item-value="id"
                    item-text="name"
                    :label="$t('battery_model_*')"
                    :rules="[rules.required]"
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-select
                    v-model="battery.battery_type_id"
                    dense
                    :items="batteryTypesList"
                    :loading="isLoadingBatteryTypes"
                    item-value="id"
                    item-text="name"
                    :label="$t('battery_type_*')"
                    :rules="[rules.required]"
                  />
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="battery.serial_no"
                    dense
                    :label="$t('serial_no_*')"
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="battery.flespi_bms_id"
                    dense
                    type="number"
                    :label="$t('bms_id')"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <small>{{ $t("indicates_required_field") }}</small>
            <v-spacer />
            <v-btn
              color="red darken-1"
              text
              @click="closeForm()"
            >
              Close
            </v-btn>
            <v-btn
              color="primary"
              text
              :loading="isLoadingSave"
              @click="saveItem(battery)"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { validationRules } from "@/mixins/validationRules";
import { mapGetters } from "vuex";

export default {
  mixins: [validationRules],
  props: {
    filterOptions: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      isVisibleDialog: false,
      isLoadingSave: false,
      battery: {},
    };
  },

  computed: {
    ...mapGetters({
      authUser: "getUser",
      tenantLoading: "tenantsManagement/getIsLoading",
      tenantsOEM: "tenantsManagement/getTenantsOEM",
      tenantsFLEET: "tenantsManagement/getTenantsFLEET",
      batteryMakesList: "batteryMakes/batteryMakesList",
      isLoadingBatteryMakesList: "batteryMakes/isLoadingBatteryMakesList",
      batteryModelsList: "batteryModels/batteryModelsList",
      isLoadingBatteryModels: "batteryModels/isLoadingBatteryModels",
      batteryTypesList: "batteryTypes/batteryTypesList",
      isLoadingBatteryTypes: "batteryTypes/isLoadingBatteryTypes",
    }),
    tenantsByProduct() {
      if (this.battery?.product) {
        if (this.battery.product == "oem") {
          return this.tenantsOEM;
        } else {
          return this.tenantsFLEET;
        }
      } else {
        return [];
      }
    },
  },

  watch: {
    isVisibleDialog(v) {
      if (this.authUser.isAdmin && v) {
        // this.battery.products = "OEM";
      }
    },
    "battery.tenant_id": {
      handler: async function (value) {
        await this.$store.dispatch("batteryMakes/list", { tenant_id: value });
      },
    },
    "battery.battery_make_id": {
      handler: async function (value) {
        await this.$store.dispatch("batteryModels/list", {
          battery_make_id: value,
        });
      },
    },
  },

  async mounted() {
    // if (!this.authUser.isAdmin) {
    //   this.battery.tenant_id = this.authUser.client_id;
    //   await this.$store.dispatch("batteryMakes/list", {
    //     tenant_id: this.authUser.client_id,
    //   });
    // }
  },

  methods: {
    async openModal() {
      this.isVisibleDialog = true;
      if (!this.authUser.isAdmin) {
        this.battery.tenant_id = this.authUser.client_id;
        await this.$store.dispatch("batteryMakes/list", {
          tenant_id: this.authUser.client_id,
        });
      }
    },
    closeForm() {
      this.$refs.form.reset();
      this.isVisibleDialog = false;
    },
    async saveItem(battery) {
      if (this.$refs.form.validate()) {
        this.isLoadingSave = true;
        const params = { battery, filter: { ...this.filterOptions } };
        await this.$store
          .dispatch("batterries/add", params)
          .then(() => {
            this.$store.dispatch(
              "alerts/success",
              this.$t("created_successfully")
            );
            this.isLoadingSave = false;
            this.closeForm();
          })
          .catch((error) => {
            this.$store.dispatch("alerts/error", error.response?.data?.message);
            this.isLoadingSave = false;
          });
      }
    },
  },
};
</script>
